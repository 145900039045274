<template>
  <v-sheet dark class="d-flex align-center justify-center fill-height">
    <div>
      <h1 class="font-weight-light">Error 404: Page not found</h1>
    </div>
  </v-sheet>
</template>

<script>
export default {
name: "Page404"
}
</script>

<style scoped>

</style>